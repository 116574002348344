import { getI18n } from 'lib/i18n/getI18n';

export const getTasksTranslation = (): Record<string, string> => {
  return {
    '84fd1_firstStepAlways': getI18n().t('countries:tasks.84fd1_firstStepAlways'),
    b1ec8_familiariseYourselfTool: getI18n().t('countries:tasks.b1ec8_familiariseYourselfTool'),
    '26f6a_startSavingFavourites': getI18n().t('countries:tasks.26f6a_startSavingFavourites'),
    '41084_useAdvancedNarrow': getI18n().t('countries:tasks.41084_useAdvancedNarrow'),
    '6087c_reviewFindGift': getI18n().t('countries:tasks.6087c_reviewFindGift'),
    '1b0b5_browseThroughBridebook': getI18n().t('countries:tasks.1b0b5_browseThroughBridebook'),
    '25a79_prepareGiveRequired': getI18n().t('countries:tasks.25a79_prepareGiveRequired'),
    aa440_droolOverDelicious: getI18n().t('countries:tasks.aa440_droolOverDelicious'),
    b2ffd_browseBridebookSave: getI18n().t('countries:tasks.b2ffd_browseBridebookSave'),
    '71389_inspiredBrowseAround': getI18n().t('countries:tasks.71389_inspiredBrowseAround'),
    '1e383_lickLipsBridebook': getI18n().t('countries:tasks.1e383_lickLipsBridebook'),
    '3a18a_wheelThroughFavourites': getI18n().t('countries:tasks.3a18a_wheelThroughFavourites'),
    ba9ee_exploreAreaMarried: getI18n().t('countries:tasks.ba9ee_exploreAreaMarried'),
    '73e03_leafThroughBridebook': getI18n().t('countries:tasks.73e03_leafThroughBridebook'),
    a1009_filterCarryBridesmaid: getI18n().t('countries:tasks.a1009_filterCarryBridesmaid'),
    b1f51_browseBridebookSave: getI18n().t('countries:tasks.b1f51_browseBridebookSave'),
    a86f1_browseMatchLooking: getI18n().t('countries:tasks.a86f1_browseMatchLooking'),
    b968d_browseNearSave: getI18n().t('countries:tasks.b968d_browseNearSave'),
    c7a3e_browseSaveLink: getI18n().t('countries:tasks.c7a3e_browseSaveLink'),
    '41b9e_browseAreaBridebook': getI18n().t('countries:tasks.41b9e_browseAreaBridebook'),
    c58a7_doubleCheckGuest: getI18n().t('countries:tasks.c58a7_doubleCheckGuest'),
    '235ae_forgetKeepUp': getI18n().t('countries:tasks.235ae_forgetKeepUp'),
    ae552_shareEngagementShoot: getI18n().t('countries:tasks.ae552_shareEngagementShoot'),
    '4e711_ensureLeavePlenty': getI18n().t('countries:tasks.4e711_ensureLeavePlenty'),
    '38be2_makeSureWedding': getI18n().t('countries:tasks.38be2_makeSureWedding'),
    '381d7_enjoyHoneymoonTwo': getI18n().t('countries:tasks.381d7_enjoyHoneymoonTwo'),
    '8c68a_makeOutingOut': getI18n().t('countries:tasks.8c68a_makeOutingOut'),
    '2bc46_settleGroomsmensAttire': getI18n().t('countries:tasks.2bc46_settleGroomsmensAttire'),
    '3c6a2_nowWhirlwindSlowing': getI18n().t('countries:tasks.3c6a2_nowWhirlwindSlowing'),
    '53f49_checkSupplierInvoices': getI18n().t('countries:tasks.53f49_checkSupplierInvoices'),
    '0258e_buyWeddingExtras': getI18n().t('countries:tasks.0258e_buyWeddingExtras'),
    '8b811_familiariseYourselfPhotography': getI18n().t(
      'countries:tasks.8b811_familiariseYourselfPhotography',
    ),
    '9b7d6_considerTableOptions': getI18n().t('countries:tasks.9b7d6_considerTableOptions'),
    ed5dc_requestMenuSuggestions: getI18n().t('countries:tasks.ed5dc_requestMenuSuggestions'),
    e36f5_recommendBridebookEngaged: getI18n().t('countries:tasks.e36f5_recommendBridebookEngaged'),
    '4a832_provideListTelephone': getI18n().t('countries:tasks.4a832_provideListTelephone'),
    '4d2cc_contactDeliciouslookingCake': getI18n().t(
      'countries:tasks.4d2cc_contactDeliciouslookingCake',
    ),
    '03354_chooseSongStart': getI18n().t('countries:tasks.03354_chooseSongStart'),
    '5c99a_onceMadeDecision': getI18n().t('countries:tasks.5c99a_onceMadeDecision'),
    '70ba0_checkLastMinute': getI18n().t('countries:tasks.70ba0_checkLastMinute'),
    '9a840_forgetDecorationsThink': getI18n().t('countries:tasks.9a840_forgetDecorationsThink'),
    a7cde_startRequestingBrochures: getI18n().t('countries:tasks.a7cde_startRequestingBrochures'),
    '8f5d4_everyonesFeedbackShowrounds': getI18n().t(
      'countries:tasks.8f5d4_everyonesFeedbackShowrounds',
    ),
    '5ef96_discussOptionsPreferences': getI18n().t(
      'countries:tasks.5ef96_discussOptionsPreferences',
    ),
    '28f73_createShortlistKey': getI18n().t('countries:tasks.28f73_createShortlistKey'),
    '6cb5d_forgetEatBreakfast': getI18n().t('countries:tasks.6cb5d_forgetEatBreakfast'),
    '2ef0b_practicePuttingDress': getI18n().t('countries:tasks.2ef0b_practicePuttingDress'),
    eff30_onceChosenFavourite: getI18n().t('countries:tasks.eff30_onceChosenFavourite'),
    '58168_sureAddAmounts': getI18n().t('countries:tasks.58168_sureAddAmounts'),
    '822fa_finaliseFriendsFamily': getI18n().t('countries:tasks.822fa_finaliseFriendsFamily'),
    b6fe9_pickFavouritesEnquire: getI18n().t('countries:tasks.b6fe9_pickFavouritesEnquire'),
    '54431_buyCakeTopper': getI18n().t('countries:tasks.54431_buyCakeTopper'),
    '4b875_startAddingGuests': getI18n().t('countries:tasks.4b875_startAddingGuests'),
    d66aa_contactFavouriteStationers: getI18n().t(
      'countries:tasks.d66aa_contactFavouriteStationers',
    ),
    e0b82_checkEnsureFavourite: getI18n().t('countries:tasks.e0b82_checkEnsureFavourite'),
    '378d3_onceMadeDecision': getI18n().t('countries:tasks.378d3_onceMadeDecision'),
    '09a4e_engagementRingCheck': getI18n().t('countries:tasks.09a4e_engagementRingCheck'),
    b052a_saveBlooms: getI18n().t('countries:tasks.b052a_saveBlooms'),
    '62832_discussWantGift': getI18n().t('countries:tasks.62832_discussWantGift'),
    '4d093_braveEnoughConsider': getI18n().t('countries:tasks.4d093_braveEnoughConsider'),
    d7bf5_forgetBookNecessary: getI18n().t('countries:tasks.d7bf5_forgetBookNecessary'),
    c5a4e_discussDecideAnyone: getI18n().t('countries:tasks.c5a4e_discussDecideAnyone'),
    e0d50_pullOutBest: getI18n().t('countries:tasks.e0d50_pullOutBest'),
    ecfe8_onceMadeOfficial: getI18n().t('countries:tasks.ecfe8_onceMadeOfficial'),
    '81c14_thinkLookBig': getI18n().t('countries:tasks.81c14_thinkLookBig'),
    b951c_finalBeautyProcedures: getI18n().t('countries:tasks.b951c_finalBeautyProcedures'),
    '5bb0c_printChosenReadings': getI18n().t('countries:tasks.5bb0c_printChosenReadings'),
    f3255_briefSomeoneWedding: getI18n().t('countries:tasks.f3255_briefSomeoneWedding'),
    '0e189_considerPackingNeed': getI18n().t('countries:tasks.0e189_considerPackingNeed'),
    '4d890_considerBookingFun': getI18n().t('countries:tasks.4d890_considerBookingFun'),
    f2d83_sortAccompanyingAccessories: getI18n().t(
      'countries:tasks.f2d83_sortAccompanyingAccessories',
    ),
    '9fdce_prepareGuestList': getI18n().t('countries:tasks.9fdce_prepareGuestList'),
    fafaf_finalisePrintMenus: getI18n().t('countries:tasks.fafaf_finalisePrintMenus'),
    fec97_thinkEnvisionArriving: getI18n().t('countries:tasks.fec97_thinkEnvisionArriving'),
    f7861_onceDecidedStationery: getI18n().t('countries:tasks.f7861_onceDecidedStationery'),
    '2bdd1_considerSendingWritten': getI18n().t('countries:tasks.2bdd1_considerSendingWritten'),
    '685fd_decideWiderWedding': getI18n().t('countries:tasks.685fd_decideWiderWedding'),
    '86c1e_arrangeDayTips': getI18n().t('countries:tasks.86c1e_arrangeDayTips'),
    ae751_considerBookAd: getI18n().t('countries:tasks.ae751_considerBookAd'),
    '24712_activateLinkToscrapbook': getI18n().t('countries:tasks.24712_activateLinkToscrapbook'),
    '5369a_askAvailabilityAround': getI18n().t('countries:tasks.5369a_askAvailabilityAround'),
    aadf0_nearestDearestKnow: getI18n().t('countries:tasks.aadf0_nearestDearestKnow'),
    '3dc7b_orderWeddingDay': getI18n().t('countries:tasks.3dc7b_orderWeddingDay'),
    '0ca71_decideWantDress': getI18n().t('countries:tasks.0ca71_decideWantDress'),
    '201e0_discussWhetherInvite': getI18n().t('countries:tasks.201e0_discussWhetherInvite'),
    '08c63_oneLastFinal': getI18n().t('countries:tasks.08c63_oneLastFinal'),
    '64efa_browseJewellersBridebook': getI18n().t('countries:tasks.64efa_browseJewellersBridebook'),
    '6f04e_createListMusthave': getI18n().t('countries:tasks.6f04e_createListMusthave'),
    ace12_readBringTrials: getI18n().t('countries:tasks.ace12_readBringTrials'),
    e8665_sitBackRelax: getI18n().t('countries:tasks.e8665_sitBackRelax'),
    '16fe9_createExtraCopies': getI18n().t('countries:tasks.16fe9_createExtraCopies'),
    baa90_orderInvitationsMake: getI18n().t('countries:tasks.baa90_orderInvitationsMake'),
    '8890a_spendEveningListening': getI18n().t('countries:tasks.8890a_spendEveningListening'),
    d16b4_bestDayLife: getI18n().t('countries:tasks.d16b4_bestDayLife'),
    ab57f_onceDecidedSave: getI18n().t('countries:tasks.ab57f_onceDecidedSave'),
    '1030b_stationeryModeWrite': getI18n().t('countries:tasks.1030b_stationeryModeWrite'),
    '06794_makeSureRelevant': getI18n().t('countries:tasks.06794_makeSureRelevant'),
    '4f2dd_decideHavingReligious': getI18n().t('countries:tasks.4f2dd_decideHavingReligious'),
    '36dcc_finaliseGiftList': getI18n().t('countries:tasks.36dcc_finaliseGiftList'),
    '90b7d_takePicturesAngle': getI18n().t('countries:tasks.90b7d_takePicturesAngle'),
    c137d_sureArrangeAdditional: getI18n().t('countries:tasks.c137d_sureArrangeAdditional'),
    '7748b_ensureBoughtEnough': getI18n().t('countries:tasks.7748b_ensureBoughtEnough'),
    d4f46_workDiyGreat: getI18n().t('countries:tasks.d4f46_workDiyGreat'),
    '9dcc7_considerCustomWedding': getI18n().t('countries:tasks.9dcc7_considerCustomWedding'),
    b3f2c_contactPrettiestJewellers: getI18n().t('countries:tasks.b3f2c_contactPrettiestJewellers'),
    '7ca1c_sendFeelsGood': getI18n().t('countries:tasks.7ca1c_sendFeelsGood'),
    c592d_rehearseStoryTry: getI18n().t('countries:tasks.c592d_rehearseStoryTry'),
    '9105c_decideMusthavesSuch': getI18n().t('countries:tasks.9105c_decideMusthavesSuch'),
    '7a5ef_requestShowroundsVenues': getI18n().t('countries:tasks.7a5ef_requestShowroundsVenues'),
    b83f4_askDetailedFinal: getI18n().t('countries:tasks.b83f4_askDetailedFinal'),
    '18ba4_considerAdditionalCosts': getI18n().t('countries:tasks.18ba4_considerAdditionalCosts'),
    f7a3e_thinkTypeHoneymoon: getI18n().t('countries:tasks.f7a3e_thinkTypeHoneymoon'),
    '47b8d_decideHolding': getI18n().t('countries:tasks.47b8d_decideHolding'),
    '90b4c_ensureMarriageLicense': getI18n().t('countries:tasks.90b4c_ensureMarriageLicense'),
    '01812_carefullySelectUltimate': getI18n().t('countries:tasks.01812_carefullySelectUltimate'),
    de23a_makeArrangementsSpecial: getI18n().t('countries:tasks.de23a_makeArrangementsSpecial'),
    '0afce_manicurePedicureBridal': getI18n().t('countries:tasks.0afce_manicurePedicureBridal'),
    '3ea3e_beforeForgetGood': getI18n().t('countries:tasks.3ea3e_beforeForgetGood'),
    b6815_gatherIdeasEndless: getI18n().t('countries:tasks.b6815_gatherIdeasEndless'),
    dee8b_enjoyRespectiveCombined: getI18n().t('countries:tasks.dee8b_enjoyRespectiveCombined'),
    ba2fd_specialisedJewelleryInsurance: getI18n().t(
      'countries:tasks.ba2fd_specialisedJewelleryInsurance',
    ),
    '79fbf_considerTransportGuests': getI18n().t('countries:tasks.79fbf_considerTransportGuests'),
    '73c54_thinkTypeEntertainment': getI18n().t('countries:tasks.73c54_thinkTypeEntertainment'),
    '7db0d_dressDryCleaned': getI18n().t('countries:tasks.7db0d_dressDryCleaned'),
    '584b3_recordRsvPsLink': getI18n().t('countries:tasks.584b3_recordRsvPsLink'),
    '74a95_onceReadyDivide': getI18n().t('countries:tasks.74a95_onceReadyDivide'),
    cc261_forgetIncludeInformation: getI18n().t('countries:tasks.cc261_forgetIncludeInformation'),
    e9fdf_takeDeepBreath: getI18n().t('countries:tasks.e9fdf_takeDeepBreath'),
    '47f25_calculateEstimatedFinal': getI18n().t('countries:tasks.47f25_calculateEstimatedFinal'),
    '8eb06_considerStyleGroom': getI18n().t('countries:tasks.8eb06_considerStyleGroom'),
    '90f27_considerNeedMusicians': getI18n().t('countries:tasks.90f27_considerNeedMusicians'),
    cc6f8_checkDatesWork: getI18n().t('countries:tasks.cc6f8_checkDatesWork'),
    e543a_approachReligiousInstitution: getI18n().t(
      'countries:tasks.e543a_approachReligiousInstitution',
    ),
    '0e4df_knowVenueTeam': getI18n().t('countries:tasks.0e4df_knowVenueTeam'),
    c1db6_startWritingPracticing: getI18n().t('countries:tasks.c1db6_startWritingPracticing'),
    b25da_remindGoHair: getI18n().t('countries:tasks.b25da_remindGoHair'),
    a34ed_showIncredibleDay: getI18n().t('countries:tasks.a34ed_showIncredibleDay'),
    '993a7_rehearsal': getI18n().t('countries:tasks.993a7_rehearsal'),
    b0ab7_finalisePrintOrder: getI18n().t('countries:tasks.b0ab7_finalisePrintOrder'),
    ca625_decideClarifyBridesmaids: getI18n().t('countries:tasks.ca625_decideClarifyBridesmaids'),
    '99ddb_arrangeEngagementPhotoshoot': getI18n().t(
      'countries:tasks.99ddb_arrangeEngagementPhotoshoot',
    ),
    cccfa_zoneStartThinking: getI18n().t('countries:tasks.cccfa_zoneStartThinking'),
    bddd0_pokeFingerGlobe: getI18n().t('countries:tasks.bddd0_pokeFingerGlobe'),
    '50a2a_trySeeMany': getI18n().t('countries:tasks.50a2a_trySeeMany'),
    b3d54_makeSureWhole: getI18n().t('countries:tasks.b3d54_makeSureWhole'),
    '3bb11_writeWeddingVows': getI18n().t('countries:tasks.3bb11_writeWeddingVows'),
    '4f7a4_checkUpDate': getI18n().t('countries:tasks.4f7a4_checkUpDate'),
    ac0d5_sureSpeakUp: getI18n().t('countries:tasks.ac0d5_sureSpeakUp'),
    d52f4_thankSuppliersLeaving: getI18n().t('countries:tasks.d52f4_thankSuppliersLeaving'),
    '767a6_makeFinalSeating': getI18n().t('countries:tasks.767a6_makeFinalSeating'),
    '700e0_thinkFunWays': getI18n().t('countries:tasks.700e0_thinkFunWays'),
    f1bb4_dropBigHints: getI18n().t('countries:tasks.f1bb4_dropBigHints'),
    '6192c_onceDecidedDresses': getI18n().t('countries:tasks.6192c_onceDecidedDresses'),
    a0e08_settleTotalEstimated: getI18n().t('countries:tasks.a0e08_settleTotalEstimated'),
    '5b93a_discussPartnerWant': getI18n().t('countries:tasks.5b93a_discussPartnerWant'),
    '5e1dd_makeSureUp': getI18n().t('countries:tasks.5e1dd_makeSureUp'),
    '682f5_finaliseGiftList': getI18n().t('countries:tasks.682f5_finaliseGiftList'),
    '9c84a_makeSurePlan': getI18n().t('countries:tasks.9c84a_makeSurePlan'),
    adaba_insureRingsCovered: getI18n().t('countries:tasks.adaba_insureRingsCovered'),
    '6db86_mustreadArticleHandy': getI18n().t('countries:tasks.6db86_mustreadArticleHandy'),
    c5afc_ensureOutstandingPayments: getI18n().t('countries:tasks.c5afc_ensureOutstandingPayments'),
    '29442_thinkCakeStyles': getI18n().t('countries:tasks.29442_thinkCakeStyles'),
    '09c19_touchAskAvailability': getI18n().t('countries:tasks.09c19_touchAskAvailability'),
    a61fa_confirmResponsibleReturning: getI18n().t(
      'countries:tasks.a61fa_confirmResponsibleReturning',
    ),
    fedda_researchContactFavourite: getI18n().t('countries:tasks.fedda_researchContactFavourite'),
    bb3f1_giveWeddingBands: getI18n().t('countries:tasks.bb3f1_giveWeddingBands'),
    '9a8e8_makeSureGift': getI18n().t('countries:tasks.9a8e8_makeSureGift'),
    '4379d_onceFoundPerfect': getI18n().t('countries:tasks.4379d_onceFoundPerfect'),
    c3fc6_tasteTestArrange: getI18n().t('countries:tasks.c3fc6_tasteTestArrange'),
    '6462d_contactStylishHairdressers': getI18n().t(
      'countries:tasks.6462d_contactStylishHairdressers',
    ),
    '832d8_chatPartnerType': getI18n().t('countries:tasks.832d8_chatPartnerType'),
    '92ebd_eatLotsCake': getI18n().t('countries:tasks.92ebd_eatLotsCake'),
    fd4f6_considerWantDay: getI18n().t('countries:tasks.fd4f6_considerWantDay'),
    fa4c6_tryInsureRing: getI18n().t('countries:tasks.fa4c6_tryInsureRing'),
    '9eb9a_onceMadeDecision': getI18n().t('countries:tasks.9eb9a_onceMadeDecision'),
    b62a5_decideManyBridesmaids: getI18n().t('countries:tasks.b62a5_decideManyBridesmaids'),
    be780_chooseVenueWant: getI18n().t('countries:tasks.be780_chooseVenueWant'),
    faccd_crackOpenBubbly: getI18n().t('countries:tasks.faccd_crackOpenBubbly'),
    '14a34_onePersonTrust': getI18n().t('countries:tasks.14a34_onePersonTrust'),
    '5afbd_addOfficialWedding': getI18n().t('countries:tasks.5afbd_addOfficialWedding'),
    '0271a_contactTopContenders': getI18n().t('countries:tasks.0271a_contactTopContenders'),
    '6261b_shoutFarWide': getI18n().t('countries:tasks.6261b_shoutFarWide'),
    '223e6_signContractKeep': getI18n().t('countries:tasks.223e6_signContractKeep'),
    a3ee5_breatheSighRelief: getI18n().t('countries:tasks.a3ee5_breatheSighRelief'),
    '6ecac_incorporatingWeddingFavours': getI18n().t(
      'countries:tasks.6ecac_incorporatingWeddingFavours',
    ),
    dcbe8_remindEveryoneWedding: getI18n().t('countries:tasks.dcbe8_remindEveryoneWedding'),
    '2b2ed_arrangeAppointmentsDress': getI18n().t('countries:tasks.2b2ed_arrangeAppointmentsDress'),
    f2001_thinkUseDay: getI18n().t('countries:tasks.f2001_thinkUseDay'),
    '2f9f3_rewardYourselfBeing': getI18n().t('countries:tasks.2f9f3_rewardYourselfBeing'),
    '28736_prepareThankCards': getI18n().t('countries:tasks.28736_prepareThankCards'),
    d32a9_decideExampleWant: getI18n().t('countries:tasks.d32a9_decideExampleWant'),
    b37aa_thinkMatchingNail: getI18n().t('countries:tasks.b37aa_thinkMatchingNail'),
    '3acd4_thinkGuestsTry': getI18n().t('countries:tasks.3acd4_thinkGuestsTry'),
    e9d00_thinkStyleStationery: getI18n().t('countries:tasks.e9d00_thinkStyleStationery'),
    '58276_addPricesFavourite': getI18n().t('countries:tasks.58276_addPricesFavourite'),
    '0aaf4_forgetAccessoriesSort': getI18n().t('countries:tasks.0aaf4_forgetAccessoriesSort'),
    '39950_checkUnderOver': getI18n().t('countries:tasks.39950_checkUnderOver'),
    '38e5d_keepSavingIdeas': getI18n().t('countries:tasks.38e5d_keepSavingIdeas'),
    cb185_decideWhetherWant: getI18n().t('countries:tasks.cb185_decideWhetherWant'),
    '79ae0_havingBridesmaidsResearch': getI18n().t(
      'countries:tasks.79ae0_havingBridesmaidsResearch',
    ),
    b261c_buyBridalAccessories: getI18n().t('countries:tasks.b261c_buyBridalAccessories'),
    '7c583_addCustomSubtasks': getI18n().t('countries:tasks.7c583_addCustomSubtasks'),
    '42ae9_watchBridesmaidsMovie': getI18n().t('countries:tasks.42ae9_watchBridesmaidsMovie'),
    '54a79_contactFavouriteTransport': getI18n().t(
      'countries:tasks.54a79_contactFavouriteTransport',
    ),
    a3e60_considerStyleCatering: getI18n().t('countries:tasks.a3e60_considerStyleCatering'),
    c74ce_decideSomethingNew: getI18n().t('countries:tasks.c74ce_decideSomethingNew'),
    '86034_enjoySharingJourney': getI18n().t('countries:tasks.86034_enjoySharingJourney'),
    '298c1_bookHoneymoon': getI18n().t('countries:tasks.298c1_bookHoneymoon'),
    '80ed7_confirmDeliverySetup': getI18n().t('countries:tasks.80ed7_confirmDeliverySetup'),
    '81fe1_discussDecidePartner': getI18n().t('countries:tasks.81fe1_discussDecidePartner'),
    '32081_goPageScroll': getI18n().t('countries:tasks.32081_goPageScroll'),
    '7a270_collectInspirationDresses': getI18n().t(
      'countries:tasks.7a270_collectInspirationDresses',
    ),
    bb4ea_scheduleAdditionalBeauty: getI18n().t('countries:tasks.bb4ea_scheduleAdditionalBeauty'),
    fbb3b_collectInspirationFavour: getI18n().t('countries:tasks.fbb3b_collectInspirationFavour'),
    a8ae9_useJotDown: getI18n().t('countries:tasks.a8ae9_useJotDown'),
    '2050d_activateToolGenerate': getI18n().t('countries:tasks.2050d_activateToolGenerate'),
    '0867b_arrangeWeddingDress': getI18n().t('countries:tasks.0867b_arrangeWeddingDress'),
    e1c29_practiceWalkingMoving: getI18n().t('countries:tasks.e1c29_practiceWalkingMoving'),
    '2cf03_markVenueBooked': getI18n().t('countries:tasks.2cf03_markVenueBookedWithLink'),
    '9bc9e_forgetPassportTravel': getI18n().t('countries:tasks.9bc9e_forgetPassportTravel'),
    '5744c_takeAdvantageFeatures': getI18n().t('countries:tasks.5744c_takeAdvantageFeatures'),
    a1027_markBooked: getI18n().t('countries:tasks.a1027_markBooked'),
    f3bd6_compileInformationSheet: getI18n().t('countries:tasks.f3bd6_compileInformationSheet'),
    a8fd4_decidePrintingWriting: getI18n().t('countries:tasks.a8fd4_decidePrintingWriting'),
    d3eb8_familiariseYourselfHandy: getI18n().t('countries:tasks.d3eb8_familiariseYourselfHandy'),
    '0ff1a_onceMadeUp': getI18n().t('countries:tasks.0ff1a_onceMadeUp'),
    '792a7_decideTypeSavethedates': getI18n().t('countries:tasks.792a7_decideTypeSavethedates'),
    '9618d_wantPartyBefore': getI18n().t('countries:tasks.9618d_wantPartyBefore'),
    '7fc05_decideWantChoreographed': getI18n().t('countries:tasks.7fc05_decideWantChoreographed'),
    '98de4_leaveEnoughTime': getI18n().t('countries:tasks.98de4_leaveEnoughTime'),
    '96941_decideOrderThe': getI18n().t('countries:tasks.96941_decideOrderThe'),
    '5eb04_communicationGamePlan': getI18n().t('countries:tasks.5eb04_communicationGamePlan'),
    f8d37_addPartnersEmail: getI18n().t('countries:tasks.f8d37_addPartnersEmail'),
    a371c_thankBridalParty: getI18n().t('countries:tasks.a371c_thankBridalParty'),
    '20971_thinkWhetherWant': getI18n().t('countries:tasks.20971_thinkWhetherWant'),
    fe282_agreeFinalLook: getI18n().t('countries:tasks.fe282_agreeFinalLook'),
    '1c482_askDetailedFinal': getI18n().t('countries:tasks.1c482_askDetailedFinal'),
    '28e76_makeEveryoneFeel': getI18n().t('countries:tasks.28e76_makeEveryoneFeel'),
    '7ee72_onceFullDecided': getI18n().t('countries:tasks.7ee72_onceFullDecided'),
    cdd81_decideWeddingHashtag: getI18n().t('countries:tasks.cdd81_decideWeddingHashtag'),
    '9ba3c_scheduleNecessaryDress': getI18n().t('countries:tasks.9ba3c_scheduleNecessaryDress'),
    deae0_importantlyEnjoyVery: getI18n().t('countries:tasks.deae0_importantlyEnjoyVery'),
    '677a5_informVenueBook': getI18n().t('countries:tasks.677a5_informVenueBook'),
    e169a_decideMuchDiy: getI18n().t('countries:tasks.e169a_decideMuchDiy'),
    '60b4b_saveNotesEasy': getI18n().t('countries:tasks.60b4b_saveNotesEasy'),
    '37bae_useNotesSave': getI18n().t('countries:tasks.37bae_useNotesSave'),
    '08cc7_packTissuesCase': getI18n().t('countries:tasks.08cc7_packTissuesCase'),
    '6fe6f_comeUpTable': getI18n().t('countries:tasks.6fe6f_comeUpTable'),
    '930d3_familiariseYourselfVideographer': getI18n().t(
      'countries:tasks.930d3_familiariseYourselfVideographer',
    ),
    '74b78_kickFitnessPlan': getI18n().t('countries:tasks.74b78_kickFitnessPlan'),
    '8a8af_organiseClothesPack': getI18n().t('countries:tasks.8a8af_organiseClothesPack'),
    c01c6_recuperateMakeSure: getI18n().t('countries:tasks.c01c6_recuperateMakeSure'),
    d8462_decideWantOrganise: getI18n().t('countries:tasks.d8462_decideWantOrganise'),
    '1a4de_savePricesFavourite': getI18n().t('countries:tasks.1a4de_savePricesFavourite'),
    e229f_buyStylishUmbrellas: getI18n().t('countries:tasks.e229f_buyStylishUmbrellas'),
    b7340_cashChequesReady: getI18n().t('countries:tasks.b7340_cashChequesReady'),
    b1d72_bringInspirationGathered: getI18n().t('countries:tasks.b1d72_bringInspirationGathered'),
    '90b00_thinkWhetherWeddingLoan': getI18n().t('countries:tasks.90b00_thinkWhetherWeddingLoan'),
    '54747_enquireSeveralWeddingInsurance': getI18n().t(
      'countries:tasks.54747_enquireSeveralWeddingInsurance',
    ),
    fbd6e_bookInsuranceBreathe: getI18n().t('countries:tasks.fbd6e_bookInsuranceBreathe'),
    c1eab_decideOrganiseEvent: getI18n().t('countries:tasks.c1eab_decideOrganiseEvent'),
    c66bd_prepareGuestList: getI18n().t('countries:tasks.c66bd_prepareGuestList'),
    e6951_dropHintsLocation: getI18n().t('countries:tasks.e6951_dropHintsLocation'),
    '41e65_buyWeddingExtras': getI18n().t('countries:tasks.41e65_buyWeddingExtras'),
    f9730_wearWeddingShoes: getI18n().t('countries:tasks.f9730_wearWeddingShoes'),
    '5668e_weddingDanceCourse': getI18n().t('countries:tasks.5668e_weddingDanceCourse'),
    '1c932_thinkWantGift': getI18n().t('countries:tasks.1c932_thinkWantGift'),
    '9b4b1_prepareMorgengabeMake': getI18n().t('countries:tasks.9b4b1_prepareMorgengabeMake'),
    '2e3d7_thinkFollowCustom': getI18n().t('countries:tasks.2e3d7_thinkFollowCustom'),
    '2819e_organiseSeparateAccomodation': getI18n().t(
      'countries:tasks.2819e_organiseSeparateAccomodation',
    ),
    a8e0e_polterabendBeforeWedding: getI18n().t('countries:tasks.a8e0e_polterabendBeforeWedding'),
    '4417e_logSawingTree': getI18n().t('countries:tasks.4417e_logSawingTree'),
    a28e3_spalierStehenCeremony: getI18n().t('countries:tasks.a28e3_spalierStehenCeremony'),
    '66af1_throwingRiceGuests': getI18n().t('countries:tasks.66af1_throwingRiceGuests'),
    '7cad0_treatYourselfPamper': getI18n().t('countries:tasks.7cad0_treatYourselfPamper'),
    '8537c_engagementRingPhoto': getI18n().t('countries:tasks.8537c_engagementRingPhoto'),
    '6ba86_tapTheHeartIcon': getI18n().t('countries:tasks.6ba86_tapTheHeartIcon'),
    '1HlfX_comeUpWithAnApproximateCost': getI18n().t(
      'countries:tasks.1HlfX_comeUpWithAnApproximateCost',
    ),
    PLxYX_noteDownAnyExtras: getI18n().t('countries:tasks.PLxYX_noteDownAnyExtras'),
    LhP4m_researchSeveralDifferentWeddingInsuranceProviders: getI18n().t(
      'countries:tasks.LhP4m_researchSeveralDifferentWeddingInsuranceProviders',
    ),
    JKl4E_purchaseTheBestWeddingInsurance: getI18n().t(
      'countries:tasks.JKl4E_purchaseTheBestWeddingInsurance',
    ),
  };
};
