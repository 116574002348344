import { getI18n } from 'lib/i18n/getI18n';

export const getGroupsTranslation: Record<
  string,
  () => { name?: string; intro?: string; tip?: string }
> = {
  '40ec5_beginExploringVenues': () => ({
    name: getI18n().t('countries:groups.40ec5_beginExploringVenues.name'),
    intro: getI18n().t('countries:groups.40ec5_beginExploringVenues.intro'),
    tip: getI18n().t('countries:groups.40ec5_beginExploringVenues.tip'),
  }),
  '25760_pickBridesmaidDresses': () => ({
    name: getI18n().t('countries:groups.25760_pickBridesmaidDresses.name'),
    intro: getI18n().t('countries:groups.25760_pickBridesmaidDresses.intro'),
    tip: getI18n().t('countries:groups.25760_pickBridesmaidDresses.tip'),
  }),
  '109e2_designDigitalWedding': () => ({
    name: getI18n().t('countries:groups.109e2_designDigitalWedding.name'),
    intro: getI18n().t('countries:groups.109e2_designDigitalWedding.intro'),
    tip: getI18n().t('countries:groups.109e2_designDigitalWedding.tip'),
  }),
  '108db_shopMensWedding': () => ({
    name: getI18n().t('countries:groups.108db_shopMensWedding.name'),
    intro: getI18n().t('countries:groups.108db_shopMensWedding.intro'),
    tip: getI18n().t('countries:groups.108db_shopMensWedding.tip'),
  }),
  '9ca93_startSpreadingWedding': () => ({
    name: getI18n().t('countries:groups.9ca93_startSpreadingWedding.name'),
    intro: getI18n().t('countries:groups.9ca93_startSpreadingWedding.intro'),
    tip: getI18n().t('countries:groups.9ca93_startSpreadingWedding.tip'),
  }),
  '8b239_selectStationery': () => ({
    name: getI18n().t('countries:groups.8b239_selectStationery.name'),
    intro: getI18n().t('countries:groups.8b239_selectStationery.intro'),
    tip: getI18n().t('countries:groups.8b239_selectStationery.tip'),
  }),
  '05acd_planHoneymoon': () => ({
    name: getI18n().t('countries:groups.05acd_planHoneymoon.name'),
    intro: getI18n().t('countries:groups.05acd_planHoneymoon.intro'),
    tip: getI18n().t('countries:groups.05acd_planHoneymoon.tip'),
  }),
  abdfe_bookCakeMaker: () => ({
    name: getI18n().t('countries:groups.abdfe_bookCakeMaker.name'),
    intro: getI18n().t('countries:groups.abdfe_bookCakeMaker.intro'),
    tip: getI18n().t('countries:groups.abdfe_bookCakeMaker.tip'),
  }),
  '0a1d0_pamperYourself': () => ({
    name: getI18n().t('countries:groups.0a1d0_pamperYourself.name'),
    intro: getI18n().t('countries:groups.0a1d0_pamperYourself.intro'),
    tip: getI18n().t('countries:groups.0a1d0_pamperYourself.tip'),
  }),
  '49ca0_marriageBeginHappily': () => ({
    name: getI18n().t('countries:groups.49ca0_marriageBeginHappily.name'),
    intro: getI18n().t('countries:groups.49ca0_marriageBeginHappily.intro'),
    tip: getI18n().t('countries:groups.49ca0_marriageBeginHappily.tip'),
  }),
  '3d007_curateGiftList': () => ({
    name: getI18n().t('countries:groups.3d007_curateGiftList.name'),
    intro: getI18n().t('countries:groups.3d007_curateGiftList.intro'),
    tip: getI18n().t('countries:groups.3d007_curateGiftList.tip'),
  }),
  fc757_selectSongFirst: () => ({
    name: getI18n().t('countries:groups.fc757_selectSongFirst.name'),
    intro: getI18n().t('countries:groups.fc757_selectSongFirst.intro'),
    tip: getI18n().t('countries:groups.fc757_selectSongFirst.tip'),
  }),
  bda08_buyWeddingRings: () => ({
    name: getI18n().t('countries:groups.bda08_buyWeddingRings.name'),
    intro: getI18n().t('countries:groups.bda08_buyWeddingRings.intro'),
    tip: getI18n().t('countries:groups.bda08_buyWeddingRings.tip'),
  }),
  b6151_outlineBudget: () => ({
    name: getI18n().t('countries:groups.b6151_outlineBudget.name'),
    intro: getI18n().t('countries:groups.b6151_outlineBudget.intro'),
    tip: getI18n().t('countries:groups.b6151_outlineBudget.tip'),
  }),
  a7dcc_surviveBachelorBachelorette: () => ({
    name: getI18n().t('countries:groups.a7dcc_surviveBachelorBachelorette.name'),
    intro: getI18n().t('countries:groups.a7dcc_surviveBachelorBachelorette.intro'),
    tip: getI18n().t('countries:groups.a7dcc_surviveBachelorBachelorette.tip'),
  }),
  '0f4c0_purchaseDayEssentials': () => ({
    name: getI18n().t('countries:groups.0f4c0_purchaseDayEssentials.name'),
    intro: getI18n().t('countries:groups.0f4c0_purchaseDayEssentials.intro'),
    tip: getI18n().t('countries:groups.0f4c0_purchaseDayEssentials.tip'),
  }),
  '607a1_bookHairMakeup': () => ({
    name: getI18n().t('countries:groups.607a1_bookHairMakeup.name'),
    tip: getI18n().t('countries:groups.607a1_bookHairMakeup.tip'),
  }),
  '7373a_bookPerfectVenue': () => ({
    name: getI18n().t('countries:groups.7373a_bookPerfectVenue.name'),
    intro: getI18n().t('countries:groups.7373a_bookPerfectVenue.intro'),
    tip: getI18n().t('countries:groups.7373a_bookPerfectVenue.tip'),
  }),
  '05dc3_scheduleVisitsFavourite': () => ({
    name: getI18n().t('countries:groups.05dc3_scheduleVisitsFavourite.name'),
    intro: getI18n().t('countries:groups.05dc3_scheduleVisitsFavourite.intro'),
    tip: getI18n().t('countries:groups.05dc3_scheduleVisitsFavourite.tip'),
  }),
  e7cda_insureEngagementRing: () => ({
    name: getI18n().t('countries:groups.e7cda_insureEngagementRing.name'),
    intro: getI18n().t('countries:groups.e7cda_insureEngagementRing.intro'),
    tip: getI18n().t('countries:groups.e7cda_insureEngagementRing.tip'),
  }),
  '9772c_collectRings': () => ({
    name: getI18n().t('countries:groups.9772c_collectRings.name'),
    intro: getI18n().t('countries:groups.9772c_collectRings.intro'),
    tip: getI18n().t('countries:groups.9772c_collectRings.tip'),
  }),
  '74279_confirmFinalDetails': () => ({
    name: getI18n().t('countries:groups.74279_confirmFinalDetails.name'),
    intro: getI18n().t('countries:groups.74279_confirmFinalDetails.intro'),
    tip: getI18n().t('countries:groups.74279_confirmFinalDetails.tip'),
  }),
  '0b3db_startSeatingPlan': () => ({
    name: getI18n().t('countries:groups.0b3db_startSeatingPlan.name'),
    intro: getI18n().t('countries:groups.0b3db_startSeatingPlan.intro'),
    tip: getI18n().t('countries:groups.0b3db_startSeatingPlan.tip'),
  }),
  d771a_printSupplementaryStationery: () => ({
    name: getI18n().t('countries:groups.d771a_printSupplementaryStationery.name'),
    intro: getI18n().t('countries:groups.d771a_printSupplementaryStationery.intro'),
    tip: getI18n().t('countries:groups.d771a_printSupplementaryStationery.tip'),
  }),
  '2d5be_invitePartnerPlan': () => ({
    name: getI18n().t('countries:groups.2d5be_invitePartnerPlan.name'),
    intro: getI18n().t('countries:groups.2d5be_invitePartnerPlan.intro'),
    tip: getI18n().t('countries:groups.2d5be_invitePartnerPlan.tip'),
  }),
  f4057_chooseWeddingParty: () => ({
    name: getI18n().t('countries:groups.f4057_chooseWeddingParty.name'),
    intro: getI18n().t('countries:groups.f4057_chooseWeddingParty.intro'),
    tip: getI18n().t('countries:groups.f4057_chooseWeddingParty.tip'),
  }),
  add54_planBachelorBacheloretteParties: () => ({
    name: getI18n().t('countries:groups.add54_planBachelorBacheloretteParties.name'),
    intro: getI18n().t('countries:groups.add54_planBachelorBacheloretteParties.intro'),
    tip: getI18n().t('countries:groups.add54_planBachelorBacheloretteParties.tip'),
  }),
  c5a1a_findFlorist: () => ({
    name: getI18n().t('countries:groups.c5a1a_findFlorist.name'),
    intro: getI18n().t('countries:groups.c5a1a_findFlorist.intro'),
    tip: getI18n().t('countries:groups.c5a1a_findFlorist.tip'),
  }),
  '0be5a_packPrepareHoneymoon': () => ({
    name: getI18n().t('countries:groups.0be5a_packPrepareHoneymoon.name'),
    intro: getI18n().t('countries:groups.0be5a_packPrepareHoneymoon.intro'),
    tip: getI18n().t('countries:groups.0be5a_packPrepareHoneymoon.tip'),
  }),
  a1992_chooseWeddingFavours: () => ({
    name: getI18n().t('countries:groups.a1992_chooseWeddingFavours.name'),
    intro: getI18n().t('countries:groups.a1992_chooseWeddingFavours.intro'),
    tip: getI18n().t('countries:groups.a1992_chooseWeddingFavours.tip'),
  }),
  '48889_other': () => ({
    name: getI18n().t('countries:groups.48889_other.name'),
  }),
  '9721b_unforgettableWeddingDay': () => ({
    name: getI18n().t('countries:groups.9721b_unforgettableWeddingDay.name'),
    intro: getI18n().t('countries:groups.9721b_unforgettableWeddingDay.intro'),
    tip: getI18n().t('countries:groups.9721b_unforgettableWeddingDay.tip'),
  }),
  '9cc86_chooseTransport': () => ({
    name: getI18n().t('countries:groups.9cc86_chooseTransport.name'),
    intro: getI18n().t('countries:groups.9cc86_chooseTransport.intro'),
    tip: getI18n().t('countries:groups.9cc86_chooseTransport.tip'),
  }),
  '048b8_startResearchingWedding': () => ({
    name: getI18n().t('countries:groups.048b8_startResearchingWedding.name'),
    intro: getI18n().t('countries:groups.048b8_startResearchingWedding.intro'),
    tip: getI18n().t('countries:groups.048b8_startResearchingWedding.tip'),
  }),
  c4d17_hairMakeupTrials: () => ({
    name: getI18n().t('countries:groups.c4d17_hairMakeupTrials.name'),
    intro: getI18n().t('countries:groups.c4d17_hairMakeupTrials.intro'),
    tip: getI18n().t('countries:groups.c4d17_hairMakeupTrials.tip'),
  }),
  d1b1c_bookNecessaryEvent: () => ({
    name: getI18n().t('countries:groups.d1b1c_bookNecessaryEvent.name'),
    intro: getI18n().t('countries:groups.d1b1c_bookNecessaryEvent.intro'),
    tip: getI18n().t('countries:groups.d1b1c_bookNecessaryEvent.tip'),
  }),
  '45efa_prepareWeatherPlan': () => ({
    name: getI18n().t('countries:groups.45efa_prepareWeatherPlan.name'),
    intro: getI18n().t('countries:groups.45efa_prepareWeatherPlan.intro'),
    tip: getI18n().t('countries:groups.45efa_prepareWeatherPlan.tip'),
  }),
  '4063c_decideSend': () => ({
    name: getI18n().t('countries:groups.4063c_decideSend.name'),
    intro: getI18n().t('countries:groups.4063c_decideSend.intro'),
    tip: getI18n().t('countries:groups.4063c_decideSend.tip'),
  }),
  '68cce_shopWeddingDresses': () => ({
    name: getI18n().t('countries:groups.68cce_shopWeddingDresses.name'),
    intro: getI18n().t('countries:groups.68cce_shopWeddingDresses.intro'),
    tip: getI18n().t('countries:groups.68cce_shopWeddingDresses.tip'),
  }),
  fc83d_doubleCheckBudget: () => ({
    name: getI18n().t('countries:groups.fc83d_doubleCheckBudget.name'),
    intro: getI18n().t('countries:groups.fc83d_doubleCheckBudget.intro'),
    tip: getI18n().t('countries:groups.fc83d_doubleCheckBudget.tip'),
  }),
  c5e67_writeWeddingToasts: () => ({
    name: getI18n().t('countries:groups.c5e67_writeWeddingToasts.name'),
    intro: getI18n().t('countries:groups.c5e67_writeWeddingToasts.intro'),
    tip: getI18n().t('countries:groups.c5e67_writeWeddingToasts.tip'),
  }),
  '6c4da_weddingRehearsal': () => ({
    name: getI18n().t('countries:groups.6c4da_weddingRehearsal.name'),
    intro: getI18n().t('countries:groups.6c4da_weddingRehearsal.intro'),
    tip: getI18n().t('countries:groups.6c4da_weddingRehearsal.tip'),
  }),
  '191a4_startGuestList': () => ({
    name: getI18n().t('countries:groups.191a4_startGuestList.name'),
    intro: getI18n().t('countries:groups.191a4_startGuestList.intro'),
    tip: getI18n().t('countries:groups.191a4_startGuestList.tip'),
  }),
  e3866_bookCaterer: () => ({
    name: getI18n().t('countries:groups.e3866_bookCaterer.name'),
    intro: getI18n().t('countries:groups.e3866_bookCaterer.intro'),
    tip: getI18n().t('countries:groups.e3866_bookCaterer.tip'),
  }),
  '11a7d_bookReceptionMusic': () => ({
    name: getI18n().t('countries:groups.11a7d_bookReceptionMusic.name'),
    intro: getI18n().t('countries:groups.11a7d_bookReceptionMusic.intro'),
    tip: getI18n().t('countries:groups.11a7d_bookReceptionMusic.tip'),
  }),
  bbd8e_startPreparingDcor: () => ({
    name: getI18n().t('countries:groups.bbd8e_startPreparingDcor.name'),
    intro: getI18n().t('countries:groups.bbd8e_startPreparingDcor.intro'),
    tip: getI18n().t('countries:groups.bbd8e_startPreparingDcor.tip'),
  }),
  '6a302_sendInvitations': () => ({
    name: getI18n().t('countries:groups.6a302_sendInvitations.name'),
    intro: getI18n().t('countries:groups.6a302_sendInvitations.intro'),
    tip: getI18n().t('countries:groups.6a302_sendInvitations.tip'),
  }),
  c91d3_venueSuppliers: () => ({
    name: getI18n().t('countries:groups.c91d3_venueSuppliers.name'),
  }),
  '85ebb_startSavingNotes': () => ({
    name: getI18n().t('countries:groups.85ebb_startSavingNotes.name'),
    intro: getI18n().t('countries:groups.85ebb_startSavingNotes.intro'),
    tip: getI18n().t('countries:groups.85ebb_startSavingNotes.tip'),
  }),
  '99467_decideHireVideographer': () => ({
    name: getI18n().t('countries:groups.99467_decideHireVideographer.name'),
    intro: getI18n().t('countries:groups.99467_decideHireVideographer.intro'),
    tip: getI18n().t('countries:groups.99467_decideHireVideographer.tip'),
  }),
  '23d1e_enlistWeddingParty': () => ({
    name: getI18n().t('countries:groups.23d1e_enlistWeddingParty.name'),
    intro: getI18n().t('countries:groups.23d1e_enlistWeddingParty.intro'),
    tip: getI18n().t('countries:groups.23d1e_enlistWeddingParty.tip'),
  }),
  '0833a_finaliseSeatingPlan': () => ({
    name: getI18n().t('countries:groups.0833a_finaliseSeatingPlan.name'),
    intro: getI18n().t('countries:groups.0833a_finaliseSeatingPlan.intro'),
    tip: getI18n().t('countries:groups.0833a_finaliseSeatingPlan.tip'),
  }),
  b34f1_snapUpPhotographer: () => ({
    name: getI18n().t('countries:groups.b34f1_snapUpPhotographer.name'),
    intro: getI18n().t('countries:groups.b34f1_snapUpPhotographer.intro'),
    tip: getI18n().t('countries:groups.b34f1_snapUpPhotographer.tip'),
  }),
  '3c7c2_chooseWeddingVenue': () => ({
    name: getI18n().t('countries:groups.3c7c2_chooseWeddingVenue.name'),
    intro: getI18n().t('countries:groups.3c7c2_chooseWeddingVenue.intro'),
    tip: getI18n().t('countries:groups.3c7c2_chooseWeddingVenue.tip'),
  }),
  '8931b_takeBreakNoWedding': () => ({
    name: getI18n().t('countries:groups.8931b_takeBreakNoWedding.name'),
    intro: getI18n().t('countries:groups.8931b_takeBreakNoWedding.intro'),
    tip: getI18n().t('countries:groups.8931b_takeBreakNoWedding.tip'),
  }),
  '49164_weddingAdditionals': () => ({
    name: getI18n().t('countries:groups.49164_weddingAdditionals.name'),
  }),
  '821dc_weddingAttire': () => ({
    name: getI18n().t('countries:groups.821dc_weddingAttire.name'),
  }),
  '7f96e_attendFinalDress': () => ({
    name: getI18n().t('countries:groups.7f96e_attendFinalDress.name'),
    intro: getI18n().t('countries:groups.7f96e_attendFinalDress.intro'),
    tip: getI18n().t('countries:groups.7f96e_attendFinalDress.tip'),
  }),
  e1c02_researchCeremony: () => ({
    name: getI18n().t('countries:groups.e1c02_researchCeremony.name'),
    intro: getI18n().t('countries:groups.e1c02_researchCeremony.intro'),
    tip: getI18n().t('countries:groups.e1c02_researchCeremony.tip'),
  }),
  b5c70_determineWeddingFestivities: () => ({
    name: getI18n().t('countries:groups.b5c70_determineWeddingFestivities.name'),
    intro: getI18n().t('countries:groups.b5c70_determineWeddingFestivities.intro'),
    tip: getI18n().t('countries:groups.b5c70_determineWeddingFestivities.tip'),
  }),
  e05f3_weddingInsuranceCover: () => ({
    name: getI18n().t('countries:groups.e05f3_weddingInsuranceCover.name'),
    intro: getI18n().t('countries:groups.e05f3_weddingInsuranceCover.intro'),
    tip: getI18n().t('countries:groups.e05f3_weddingInsuranceCover.tip'),
  }),
  add54_eventTraditionallyPolterabend: () => ({
    name: getI18n().t('countries:groups.add54_eventTraditionallyPolterabend.name'),
    intro: getI18n().t('countries:groups.add54_eventTraditionallyPolterabend.intro'),
    tip: getI18n().t('countries:groups.add54_eventTraditionallyPolterabend.tip'),
  }),
  '770c4_customMorningGift': () => ({
    name: getI18n().t('countries:groups.770c4_customMorningGift.name'),
    intro: getI18n().t('countries:groups.770c4_customMorningGift.intro'),
    tip: getI18n().t('countries:groups.770c4_customMorningGift.tip'),
  }),
  fafdc_accordingCustomWedding: () => ({
    name: getI18n().t('countries:groups.fafdc_accordingCustomWedding.name'),
    intro: getI18n().t('countries:groups.fafdc_accordingCustomWedding.intro'),
    tip: getI18n().t('countries:groups.fafdc_accordingCustomWedding.tip'),
  }),
  '2fdfa_germanWeddingCustoms': () => ({
    name: getI18n().t('countries:groups.2fdfa_germanWeddingCustoms.name'),
    intro: getI18n().t('countries:groups.2fdfa_germanWeddingCustoms.intro'),
    tip: getI18n().t('countries:groups.2fdfa_germanWeddingCustoms.tip'),
  }),
  d0db0_saveToFavorites: () => ({
    name: getI18n().t(
      'countries:groups.d0db0_saveToFavorites.name',
      'Save a venue or supplier to your favourites to refer back to later',
    ),
  }),
  '8537c_uploadCoverPhoto': () => ({
    name: getI18n().t(
      'countries:groups.8537c_uploadCoverPhoto.name',
      'Upload your favourite snap as a cover photo',
    ),
  }),
  add54_beginPlanningHen: () => ({
    name: getI18n().t(
      'countries:groups.add54_beginPlanningHen',
      'Begin Planning Your Hen and Stag Dos',
    ),
  }),
  a7dcc_surviveHenStag: () => ({
    name: getI18n().t('countries:groups.a7dcc_surviveHenStag', 'Survive Your Hen and Stag Dos'),
  }),
};
